import Link from "next/link";
import { ReactNode } from "react";

import Nav from "./Nav";

export default function DefaultLayout({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <div className="relative bg-gray-50">
      <Nav />

      {children}
      <div className="pt-10 pb-40 bg-white">
        <p className="space-x-2 text-sm sm:text-base text-center text-gray-600">
          <Link href="/privacy">
            <a className="underline">Privacy Policy</a>
          </Link>
          <span>|</span>
          <Link href="/terms">
            <a className="underline">Terms of Service</a>
          </Link>
          <span>|</span>
          <Link href="/cookies">
            <a className="underline">Cookie Policy</a>
          </Link>
          <span>|</span>
          <a
            className="underline"
            href="https://headwayapp.co/turnshift-changelog"
          >
            Changelog
          </a>
        </p>

        <p className="mt-10 space-x-2 text-sm sm:text-base text-center text-gray-600">
          <a className="underline" href="https://twitter.com/turnshiftapp">
            @turnshiftapp
          </a>
          <span>|</span>
          <Link href="/support">
            <a className="underline">Support</a>
          </Link>
        </p>
      </div>
    </div>
  );
}
