import { Transition } from "@headlessui/react";
import cx from "classnames";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { useWindowScroll, useClickAway } from "react-use";

import useUser from "lib/hooks/useUser";
import ArrowNarrowRightIcon from "svg/heroicons/arrow-narrow-right.svg";
import MenuAlt2Icon from "svg/heroicons/menu-alt-2.svg";
import XIcon from "svg/heroicons/x.svg";
import Logo from "svg/logo.svg";
import SlackLogoMark from "svg/slack-logo-mark.svg";

export default function Nav(): JSX.Element {
  const { user } = useUser();
  const { y: windowScrollY } = useWindowScroll();

  const [cssClasses, setCssClasses] = useState("");
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (windowScrollY > 40) {
      setCssClasses("bg-white bg-opacity-75 border-b border-gray-200");
      setStyles({
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
      });
    } else {
      setStyles({});
      setCssClasses("");
    }
  }, [windowScrollY]);

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const homePagePath = user?.isLoggedIn ? "/home" : "/";

  return (
    <>
      <div
        className={cx("sticky top-0 z-30", cssClasses)}
        style={{ ...styles, transition: ".5s filter, .5s background-color" }}
      >
        <MobileNav
          close={() => {
            setIsMobileNavOpen(false);
          }}
          isOpen={isMobileNavOpen}
        />
        <div
          className={cx("py-4 px-4 sm:px-6 mx-auto max-w-7xl", {
            hidden: isMobileNavOpen,
          })}
        >
          <nav className="flex relative justify-between xl:justify-center items-center sm:h-10">
            <div className="flex xl:absolute xl:inset-y-0 xl:left-0 flex-1 items-center">
              <div className="flex justify-between items-center w-full xl:w-auto">
                <Link href="/">
                  <a>
                    <Logo className="mx-auto xl:mx-0 w-auto h-8" />
                  </a>
                </Link>
                <div className="flex xl:hidden items-center -mr-2">
                  <button
                    aria-haspopup="true"
                    aria-label="Main menu"
                    className="inline-flex justify-center items-center p-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 hover:bg-gray-100 focus:bg-gray-100 rounded-md transition duration-150 ease-in-out focus:outline-none"
                    id="main-menu"
                    onClick={() => {
                      setIsMobileNavOpen(!isMobileNavOpen);
                    }}
                    type="button"
                  >
                    <MenuAlt2Icon className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden xl:flex xl:items-center xl:space-x-10">
              <a
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                href={`${homePagePath}#features`}
              >
                Features
              </a>
              <a
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                href={`${homePagePath}#pricing`}
              >
                Pricing
              </a>
              <a
                className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                href={`${homePagePath}#faq`}
              >
                Faq
              </a>
              {!user?.organization?.slug && (
                <div className="rounded-md border shadow">
                  <a // eslint-disable-line @next/next/no-html-link-for-pages
                    className="group flex justify-center items-center py-1.5 px-3 w-full text-base text-white hover:text-gray-900 bg-indigo-600 hover:bg-white rounded-md border border-transparent focus:border-indigo-700 focus:ring-indigo-500 transition duration-150 ease-in-out focus:outline-none"
                    href="/api/slack/directInstallRedirect?fromHomePage=yes"
                  >
                    <div className="p-1 mr-3 bg-white rounded-full">
                      <SlackLogoMark className="w-5 h-5" />
                    </div>{" "}
                    <div>
                      Add to <strong>Slack</strong>
                    </div>
                  </a>
                </div>
              )}
            </div>
            <div className="hidden xl:flex xl:absolute xl:inset-y-0 xl:right-0 xl:justify-end xl:items-center">
              {user?.organization?.slug ? (
                <Link
                  href={
                    user.organization.slackBotToken === null
                      ? `/app/${user.organization.slug}/settings/slack`
                      : `/app/${user.organization.slug}/dashboard`
                  }
                >
                  <a className="inline-flex justify-center items-center py-1 px-3 text-gray-600 hover:text-gray-900 bg-gray-200 rounded-full transition duration-150 ease-in-out">
                    Your dashboard{" "}
                    <ArrowNarrowRightIcon className="ml-1 w-4 h-4" />
                  </a>
                </Link>
              ) : (
                <a // eslint-disable-line @next/next/no-html-link-for-pages
                  className="inline-flex justify-center items-center py-1 px-3 text-gray-600 hover:text-gray-900 bg-gray-200 rounded-full transition duration-150 ease-in-out"
                  data-qa="sign-in"
                  href="/api/login/slack/redirect"
                >
                  Sign in <ArrowNarrowRightIcon className="ml-1 w-4 h-4" />
                </a>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

function MobileNav({
  isOpen = false,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}): JSX.Element {
  const { user } = useUser();
  const ref = useRef(null);
  useClickAway(ref, close);
  const homePagePath = user?.isLoggedIn ? "/home" : "/";

  return (
    <div ref={ref}>
      <Transition
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        show={isOpen}
      >
        {(): JSX.Element => {
          return (
            <div
              className="xl:hidden fixed inset-x-0 top-0 z-40 p-2 w-full transition transform origin-top-right"
              ref={ref}
            >
              <div className="rounded-lg shadow-md">
                <div
                  aria-labelledby="main-menu"
                  aria-orientation="vertical"
                  className="overflow-hidden bg-white rounded-lg shadow-sm"
                  role="menu"
                >
                  <div className="flex justify-between items-center px-5 pt-4">
                    <div>
                      <Link href="/">
                        <a>
                          <Logo className="mx-auto md:mx-0 w-auto h-8" />
                        </a>
                      </Link>
                    </div>
                    <div className="-mr-2">
                      <button
                        aria-label="Close menu"
                        className="inline-flex justify-center items-center p-2 text-gray-400 hover:text-gray-500 focus:text-gray-500 hover:bg-gray-100 focus:bg-gray-100 rounded-md transition duration-150 ease-in-out focus:outline-none"
                        onClick={close}
                        type="button"
                      >
                        <XIcon className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    <a
                      className="block py-2 px-3 text-base font-medium text-gray-700 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 rounded-md transition duration-150 ease-in-out focus:outline-none"
                      href={`${homePagePath}#features`}
                      onMouseUp={close}
                      role="menuitem"
                    >
                      Features
                    </a>
                    <a
                      className="block py-2 px-3 mt-1 text-base font-medium text-gray-700 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 rounded-md transition duration-150 ease-in-out focus:outline-none"
                      href={`${homePagePath}#pricing`}
                      onMouseUp={close}
                      role="menuitem"
                    >
                      Pricing
                    </a>
                    <a
                      className="block py-2 px-3 mt-1 text-base font-medium text-gray-700 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 rounded-md transition duration-150 ease-in-out focus:outline-none"
                      href={`${homePagePath}#faq`}
                      onMouseUp={close}
                      role="menuitem"
                    >
                      Faq
                    </a>
                    {!user?.organization?.slug && (
                      <div className="rounded-md border shadow">
                        <a // eslint-disable-line @next/next/no-html-link-for-pages
                          className="group flex justify-center items-center py-1.5 px-3 w-full text-base text-white hover:text-gray-900 bg-indigo-600 hover:bg-white rounded-md border border-transparent focus:border-indigo-700 focus:ring-indigo-500 transition duration-150 ease-in-out focus:outline-none"
                          href="/api/slack/directInstallRedirect?fromHomePage=yes"
                        >
                          <div className="p-1 mr-3 bg-white rounded-full">
                            <SlackLogoMark className="w-5 h-5" />
                          </div>{" "}
                          Add to Slack
                        </a>
                      </div>
                    )}
                  </div>
                  <div>
                    {user?.organization?.slug ? (
                      <Link
                        href={
                          user.organization.slackBotToken === null
                            ? `/app/${user.organization.slug}/settings/slack`
                            : `/app/${user.organization.slug}/dashboard`
                        }
                      >
                        <a
                          className="flex justify-center items-center py-3 px-5 w-full font-medium text-center text-indigo-600 hover:text-indigo-700 focus:text-indigo-700 bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 transition duration-150 ease-in-out focus:outline-none"
                          role="menuitem"
                        >
                          Your dashboard{" "}
                          <ArrowNarrowRightIcon className="ml-1 w-4 h-4" />
                        </a>
                      </Link>
                    ) : (
                      <a // eslint-disable-line @next/next/no-html-link-for-pages
                        className="flex justify-center items-center py-3 px-5 w-full font-medium text-center text-indigo-600 hover:text-indigo-700 focus:text-indigo-700 bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 transition duration-150 ease-in-out focus:outline-none"
                        href="/api/login/slack/redirect"
                        role="menuitem"
                      >
                        Sign in{" "}
                        <ArrowNarrowRightIcon className="ml-1 w-4 h-4" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Transition>
    </div>
  );
}
